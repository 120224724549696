<template>
  <div>
    <div class="row mt-4 mb-4">
      <div class="col-sm-2 offset-sm-2"><button class="btn warning-btn" @click="obtener_facturas">Recargar facturas</button></div>
      <div class="col-sm-2"><button class="btn complementary-btn" @click="revisar_factura">Revisar factura</button></div>
      <div class="col-sm-2"><button class="btn secondary-btn" @click="realizar_pagos">Realizar pago(s)</button></div>
      <div class="col-sm-2"><button class="btn principal-btn" @click="abrir_pago_externo">Agregar pago externo</button></div>
      <div class="col-sm-2"><button class="btn danger-btn" @click="cerrar_pagos">Cancelar pagos</button></div>
    </div>

    <table class="table table-striped table-pagos">
      <thead>
        <th><BSwitch id="sel_all" name="sel_all" @checked="seleccionar_todas"/></th>
        <th>Proveedor</th>
        <th>Representante</th>
        <th>Fecha de subida</th>
        <th>Folio</th>
        <th>Total</th>
        <th>Saldo pendiente</th>
        <th>A pagar</th>
        <th>Método de pago</th>
        <th>Cuenta contable</th>
        <th>Estatus pago</th>
        <th>Descargar</th>
      </thead>
      <tbody>
        <tr v-for="factura in facturas" :class="estatus_factura_class(factura.pago_estatus)">
          <td><BSwitch :value="factura" :id="'factura_'+factura.id" :name="'factura_'+factura.id" @checked="seleccionar_factura" :disabled="factura.pago_estatus != 0 && factura.pago_estatus != 3 && factura.pago_estatus != 4"/></td>
          <td>{{ factura.empresa ? factura.empresa.empresa : 'Sin especificar' }}</td>
          <td>{{ factura.proveedor_nombre }}</td>
          <td>{{ $moment(factura.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>{{ factura.folio }}</td>
          <td>${{ $helper.moneyFormat(factura.total) }}</td>
          <td>${{ saldo_pendiente(factura) }}</td>
          <td>
            <div class="input-group input_monto">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="text" :name="'a_pagar_'+factura.id" :id="'a_pagar_'+factura.id" :data-factura="factura.id" @change="a_pagar" class="form-control" disabled>
            </div>
          </td>
          <td>{{ factura.metodo_pago }}</td>
          <td>
            <select :name="'cuenta_contable'+factura.id" :id="'cuenta_contable'+factura.id" class="form-control" :data-factura="factura.uuid" @change="seleccionar_cuenta_contable" disabled>
              <option value="na">Selecciona una cuenta contable</option>
              <option v-for="opcion in catalogo.valores" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }} ( {{ opcion.valor }} )</option>
            </select>
          </td>
          <td>{{ estatus_factura(factura.pago_estatus) }}</td>
          <td><button @click="descargar_factura(factura.id)" class="btn principal-btn">Descargar</button></td>
        </tr>
      </tbody>
    </table>

    <Modal v-if="pagar_facturas" @close="pagar_facturas=false">
      <div class="title">Pagar</div>
      <div class="body">
        <div class="row mt-4 mb-4">
          <label for="" class="col-form-label col-sm-2 offset-sm-1">Total de facturas</label>
          <div class="col-sm-3 form-control">{{ seleccionadas.length }}</div>
          <label for="" class="col-form-label col-sm-2">Total a pagar</label>
          <div class="col-sm-3 form-control">{{ total_pagar }}</div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Verificar saldo</button></div>
          <div class="col-sm-2"><button class="btn principal-btn">Pagar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="efectuar_pagos" :options="{width: '40vw', type:'warning'}">
      <div class="title">Confirmar pagos</div>
      <div class="body">
        <p class="mensaje_alerta">Estas a punto de enviar a pagar un total de <strong>{{ seleccionadas.length }}</strong> facturas a STP<br/><br/>Es necesario fondear la cuenta con lo menos <strong>${{ total_pagar}}</strong> y asegurarte que el<br/>  monto se encuentra ya disponible por STP</p>
        <p class="mensaje_alerta_pregunta">¿Deseas continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-3 offset-sm-6"><button ref="btn_enviar_pagos" class="btn principal-btn" @click="enviar_pagos">Realizar pagos</button></div>
          <div class="col-sm-3"><button class="btn danger-btn" @click="cancelar_pagos">Cancelar pagos</button></div>
        </div>
      </div>
    </Modal>

    <Externo v-if="pago_externo" :factura="seleccionadas[0]" @close="cerrar_pago_externo" @update="update_pago_externo"/>
  </div>
</template>

<script type="text/javascript">
  import IC from '@/components/Form/InputCompuesto'
  import Modal from '@/components/Modal'
  import BSwitch from '@/components/Form/Switch'

  import Externo from './PagoExterno'

  import api from '@/apps/tesoreria/api/pagos'
  import apiFacturas from '@/apps/tesoreria/api/tesoreria'
  import apiCatalogo from '@/apps/tesoreria/api/catalogos'

  export default {
    components: {
      IC, Modal, BSwitch, Externo
    },
    props: {
      factura: null
    },
    data: function() {
      return {
        seleccionadas: []
        ,facturas: []
        ,pagar_facturas: false
        ,total_pagar: 0
        ,efectuar_pagos: false
        ,pago_externo: false
        ,catalogo: {
          catalogo: null
          ,valores:[]
        }
      }
    }
    ,mounted: function() {
      this.obtener_facturas();
      this.obtener_catalogo();
    }
    ,methods: {
      obtener_facturas: async function() {
        try {
          this.facturas = (await api.obtener_facturas(this.options)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_catalogo: async function() {
        try {
          this.catalogo = (await apiCatalogo.buscar_catalogo('cuentas_contables')).data;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,seleccionar_factura: function(checked, factura, id) {
        let encontrada = false;
        let facturas = [];

        for(let i=0; i<this.seleccionadas.length; i++) {
          if(this.seleccionadas[i].id == factura.id)
            encontrada = true;

          if (checked == 0) {
            if (this.seleccionadas[i].id != factura.id){
              facturas.push(this.seleccionadas[i]);
            }
          }
        }

        // this.$log.info('factura',factura);

        let cb = document.getElementById(id);
        let cell = cb.parentElement.parentElement.parentElement;
        let cellInput = cell.parentElement.children[7];
        let row = cb.parentElement.parentElement.parentElement.parentElement;

        if (checked == 1){
          if (!encontrada) {
            let total = factura.total;

            if (factura.pagos.length > 0)
              total = factura.pagos[factura.pagos.length-1].saldo_insoluto;

            let tipo_cuenta_contable = row.children[9].children[0];

            tipo_cuenta_contable.disabled = false;

            if (factura.cuenta_contable != null)
              tipo_cuenta_contable.value = factura.cuenta_contable;

            factura.cuenta_contable = row.children[9].children[0].value;

            factura.a_pagar = parseFloat(total);
            this.seleccionadas.push(factura);
          }
          // this.$log.info('children', cellInput);
          cellInput.children[0].children[1].value = factura.a_pagar.toFixed(2);

          if (factura.metodo_pago != 'PUE')
            cellInput.children[0].children[1].disabled = false;
          else {
            cellInput.children[0].children[1].disabled = false;
            cellInput.children[0].children[1].readOnly = true;
          }
          row.classList.add('row_seleccionada');
        }else {
          row.classList.remove('row_seleccionada');
          row.children[9].children[0].disabled = true;
          row.children[9].children[0].value = 'na';
          cellInput.children[0].children[1].value = null;
          cellInput.children[0].children[1].disabled = true;
            cellInput.children[0].children[1].readOnly = false;
          this.seleccionadas = facturas;
        }

        this.actualizar_total();
      }
      ,a_pagar: function(e) {
        for(let i=0; i<this.seleccionadas.length; i++) {
          if (this.seleccionadas[i].id == e.target.dataset.factura) {
            let total = this.seleccionadas[i].total;

            if (this.seleccionadas[i].pagos.length > 0)
              total = this.seleccionadas[i].pagos[this.seleccionadas[i].pagos.length-1].saldo_insoluto;

            if (e.target.value > total){
              e.target.value = parseFloat(total).toFixed(2);
              return this.$helper.showMessage('Error','El monto a pagar no puede ser mayor al monto total o al saldo pendiente de la factura','error','alert');
            }

            this.seleccionadas[i].a_pagar = parseFloat(e.target.value);
          }
        }

        this.actualizar_total();
      }
      ,seleccionar_todas: function(checked) {
        let checkboxs = document.getElementsByTagName('input');

        checkboxs.forEach(cb => {
          if (cb.type == 'checkbox' && cb.id.indexOf('factura_') > -1) {
            if (checked == 1) {
              if (!cb.checked) {
                cb.click();
              }
            }else {
              if (cb.checked)
                cb.click();
            }
          }
        })

        this.actualizar_total();
      }
      ,actualizar_total: function() {
        let total = 0;

        this.seleccionadas.forEach(factura => {
          if (factura.a_pagar)
            total += factura.a_pagar;
        })

        this.total_pagar = total.toFixed(2);
      }
      ,realizar_pagos: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Tienes que seleccionar al menos una factura a pagar','error','alert');

        let error = false;

        for(let i=0; i<this.seleccionadas.length; i++) {
          if (this.seleccionadas[i].pago_estatus == 1) {
            i=this.seleccionadas.length;
            return this.$helper.showMessage('Error','Una de las facturas tiene estatus de pago "En transición"','error','alert');
          }

          if (!this.seleccionadas[i].cuenta_contable || this.seleccionadas[i].cuenta_contable == 'na') {
            this.$helper.showMessage('Error','Debes seleccionar el tipo de cuenta contable para la factura "'+this.seleccionadas[i].uuid+'"','error','alert');
            i=this.seleccionadas.length;
            return ;
          }
        }

        this.efectuar_pagos = true;
      }
      ,cancelar_pagos: function() {
        this.efectuar_pagos = false;
      }
      ,enviar_pagos: async function() {
        try {
          this.$refs.btn_enviar_pagos.disabled = true;
          let res = (await api.realizar_pagos({facturas: this.seleccionadas})).data;
          this.$log.info('res', res);

          this.$refs.btn_enviar_pagos.disabled = false;

          this.obtener_facturas();
          
          let checkboxs = document.getElementsByTagName('input');

          checkboxs.forEach(cb => {
            if (cb.type == 'checkbox' && cb.id.indexOf('factura_') > -1) {
              if (cb.checked) {
                cb.click();
              }
            }
          })

          this.actualizar_total();
          this.efectuar_pagos = false;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,estatus_factura_class: function(estatus) {
        let clase = '';
        switch(estatus) {
          case 1:
            clase += 'pago_estatus_transicion';
            break;
          case 3:
            clase += 'pago_estatus_pendiente';
            break;
          case 4:
            clase += 'pago_estatus_error';
            break;
        }

        return clase;
      }
      ,estatus_factura: function(estatus) {
        switch(estatus) {
          case 0:
            return 'NA';
            break;
          case 1:
            return 'En transición';
            break;
          case 2:
            return 'Finalizada';
            break;
          case 3:
            return 'Saldo pendiente';
            break;
          case 4:
            return 'Error';
            break;
        }
      }
      ,revisar_factura: async function() {
        try {
          let factura = (await api.obtener_factura(this.seleccionadas[0].id)).data;
          this.$emit('factura',factura);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_pagos: function() {
        this.$emit('cerrar');
      }
      ,saldo_pendiente: function(factura) {
        let saldo_pendiente = factura.total;

        if (factura.pagos.length > 0) {
          saldo_pendiente = factura.pagos[factura.pagos.length-1].saldo_insoluto;
        }

        saldo_pendiente = parseFloat(saldo_pendiente);

        return this.$helper.moneyFormat(saldo_pendiente);
      }
      ,abrir_pago_externo: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar la factura a pagar','error','alert');

        if (this.seleccionadas.length > 1)
          return this.$helper.showMessage('Error','Debes seleccionar solo la factura a pagar','error','alert');

        this.pago_externo = true;
      }
      ,cerrar_pago_externo: function() {
        this.seleccionar = false;
        this.pago_externo = false;

        let checkboxs = document.getElementsByTagName('input');

        checkboxs.forEach(cb => {
          if (cb.type == 'checkbox' && cb.id.indexOf('factura_') > -1) {
            if (cb.checked) {
              cb.click();
            }
          }
        })
      }
      ,update_pago_externo: function() {
        this.cerrar_pago_externo();
        this.obtener_facturas();
      }
      ,seleccionar_cuenta_contable: function(e) {
        let uuid = e.target.dataset.factura;

        for(let i=0; i<this.seleccionadas.length; i++) {
          if (this.seleccionadas[i].uuid == uuid)
            this.seleccionadas[i].cuenta_contable = e.target.value;
        }
      }
      ,descargar_factura: async function(factura_id) {
        try {
          let res = (await apiFacturas.descargar_documentos_v2(factura_id)).data;
          this.$log.info('res', res);
          let domain = process.env.VUE_APP_API_TESORERIA;
          domain = domain.replace('api/v1','storage');
          window.open(domain+'/'+res.file);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      factura: function(val) {
        if (!val) {
          let checkboxs = document.getElementsByTagName('input');

          checkboxs.forEach(cb => {
            if (cb.type == 'checkbox' && cb.id.indexOf('factura_') > -1) {
              if (cb.checked) {
                cb.click();
              }
            }
          })

          this.actualizar_total();
        }
      }
    }
  }
</script>

<style lang="scss">
  .table-pagos {
    thead {
      background-color: #245671;
    }
  }

  .row_seleccionada {
    background-color: #D6CC82 !important;
  }

  .mensaje_alerta, .mensaje_alerta_pregunta {
    font-size: 1.1em;
    text-align: center;
  }

  .mensaje_alerta_pregunta {
    font-weight: bold;
  }

  .pago_estatus_transicion {
    background-color: #A1C5EC !important;
  }

  .pago_estatus_pendiente {
    background-color: #676767 !important;
    color: #fff;
  }

  .pago_estatus_error {
    background-color: #E89F9F !important;
  }

  .input_monto {
    width: 150px !important;
  }
</style>